import { Get, Post } from './common'

export const getWorkouts = async (accessToken, page, pageSize) => {
    const url = process.env.REACT_APP_BASE_API_ADDRESS + "Workouts/paged?page=" + page + "&pageSize="+ pageSize;
    return await Get(accessToken, url);
}

export const getAllWorkouts = async (accessToken) => {
    const url = process.env.REACT_APP_BASE_API_ADDRESS + "Workouts/all";
    return await Get(accessToken, url);
}

export const searchWorkouts = async (accessToken, word) => {
    const url = process.env.REACT_APP_BASE_API_ADDRESS + "Workouts/Search?word=" + word;
    return await Get(accessToken, url);
}

export const addWorkout = async function(accessToken, formState) {
    const url = process.env.REACT_APP_BASE_API_ADDRESS + "Workouts/";
    const body = JSON.stringify(formState);
    return await Post(accessToken, url, body);
}