import { Get, Post, Delete } from './common'

export const getResults = async (accessToken, id) => {
    const url = process.env.REACT_APP_BASE_API_ADDRESS + "workoutResults?workoutId=" + id;
    return await Get(accessToken, url);
}

export const getAllResults = async (accessToken) => {
    const url = process.env.REACT_APP_BASE_API_ADDRESS + "workoutResults/all"
    return await Get(accessToken, url);
}

export const saveResult = async (accessToken, from) => {
    const url = process.env.REACT_APP_BASE_API_ADDRESS + "workoutResults/";
    const body = JSON.stringify(from);
    return await Post(accessToken, url, body);
}

export const deleteResult = async (accessToken, id) => {
    const url = process.env.REACT_APP_BASE_API_ADDRESS + "workoutResults?resultId=" + id;
    return await Delete(accessToken, url);
}