import { Get } from './common'

export const getWorkoutFrequency = async (accessToken, sinceMonths, email) => {
    const url = process.env.REACT_APP_BASE_API_ADDRESS + "stats/wodfrequency?sinceMonths=" + sinceMonths + "&email=" + email;
    return Get(accessToken, url);
}

export const getWorkoutPerformance = async (accessToken, email) => {
    const url = process.env.REACT_APP_BASE_API_ADDRESS + "stats/wodperformance?email=" + email;
    return Get(accessToken, url);
}