import React from 'react';
import Loading from '../../loading.png';

const Loader = (props) => {
	return (  
		<div className="loading-container">
			<img src={Loading} className="loading-image" alt="HASI"/>
			<hr className="loading-line"/>
			<p className="loading-text">{props.text}</p>
		</div>
	) 
}

export default Loader;