import React, { useState, useEffect } from 'react';

import SearchComponent from '../common/search';
import SnackbarComponent from '../common/snackbar';
import LoaderComponent from '../common/loader';

import WorkoutRecordComponent from './workoutRecordComponent';
import WorkoutForm from './workoutForm';

import { saveResult, deleteResult, getAllResults } from '../../api/workoutResults';
import { searchWorkouts, getAllWorkouts, addWorkout } from '../../api/workouts';
import { useAuth0 } from '@auth0/auth0-react';
import Modal from '../common/modal';

const pageSize = 10;

const WorkoutContainerComponent = () => {
	const { getAccessTokenSilently } = useAuth0();
	const [isLoaded, setIsLoaded] = useState();
	const [allWorkouts, setAllWorkouts] = useState([]);
	const [allResults, setAllResults] = useState([]);
	const [currentWorkouts, setCurrentWorkouts] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [moreWorkouts, setMoreWorkouts] = useState();
	const [showModal, setShowModal] = useState();
	const [error, setError] = useState();

	const wodSnackbarRef = React.useRef();

	const showSnackbarHandler = (message) => {
		wodSnackbarRef.current.show(message);
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const accessToken = await getAccessTokenSilently();
				const { workouts, workoutsToDisplay, moreItems }  = await getWorkoutsToDisplay(accessToken);
				const workoutResults = await getAllResults(accessToken);
	
				setAllWorkouts(workouts);
				setCurrentWorkouts(workoutsToDisplay);
				setMoreWorkouts(moreItems);
				setAllResults(workoutResults);
				setIsLoaded(true);
			} catch (e) {
				setError(e)
			}
		};
	  
		fetchData()
	  }, [getAccessTokenSilently]);

	const createWorkout = async (form) => {
		try {
			const accessToken = await getAccessTokenSilently();
			const workout = await addWorkout(accessToken, form);
			let newWorkouts = currentWorkouts;
			newWorkouts.unshift(workout);
	
			setCurrentWorkouts(newWorkouts);
			setShowModal(false);
			showSnackbarHandler("Workout saved.");
		} catch (e) {
			setError(e)
		}
	}

	const clear = async () => {
		try {
			setIsLoaded(false);
			const accessToken = await getAccessTokenSilently();
			const { workouts, workoutsToDisplay, moreItems }  = await getWorkoutsToDisplay(accessToken);
			setAllWorkouts(workouts);
			setCurrentPage(1);
			setCurrentWorkouts(workoutsToDisplay);
			setMoreWorkouts(moreItems);
			setIsLoaded(true);
		} catch (e) {
			setError(e)
		}
	}

	const search = async (word) => {
		try {
			if (word !== "") {
				const accessToken = await getAccessTokenSilently();
				let workouts = await searchWorkouts(accessToken, word);
				let workoutsToDisplay = workouts.slice(0, pageSize);
				let moreItems = workoutsToDisplay.length === pageSize;
	
				setCurrentPage(1);
				setMoreWorkouts(moreItems);
				setAllWorkouts(workouts);
				setCurrentWorkouts(workoutsToDisplay);
			}
		} catch (e) {
			setError(e)
		}
	}

	const deleteResultState = async (id) => {
		try {
			const accessToken = await getAccessTokenSilently();
			await deleteResult(accessToken, id);
			const newResults = allResults.filter(x => x.id !== id);
			setAllResults(newResults);
			showSnackbarHandler("Result removed.");
		} catch (e) {
			setError(e)
		}
	}

	const submitResultState = async (form) => {
		try {
			const accessToken = await getAccessTokenSilently();
			const workoutResult = await saveResult(accessToken, form);
			const updatedResults = [workoutResult, ...allResults];
			setAllResults(updatedResults);
			showSnackbarHandler("Result saved.");
		} catch (e) {
			setError(e)
		}
	}

	const randomWorkout = async () => {
		let randomIndex = Math.floor(Math.random() * allWorkouts.length);
		let randomlySelectedWod = new Array(allWorkouts[randomIndex]);

		setCurrentPage(1);
		setIsLoaded(true);
		setCurrentWorkouts(randomlySelectedWod);
	}

	const more = () => {
		let newPageNumber = currentPage + 1;
		let start = currentPage * pageSize;
		let end = start + pageSize;
		let nextItems = allWorkouts.slice(start, end);
		let workoutsToDisplay = currentWorkouts.concat(nextItems);

		setMoreWorkouts(nextItems.length === pageSize);
		setCurrentPage(newPageNumber);
		setCurrentWorkouts(workoutsToDisplay);
	}

	const getWorkoutsToDisplay = async (accessToken) => {
		const workouts = await getAllWorkouts(accessToken);
		const workoutsToDisplay = workouts.slice(0, pageSize);
		const moreItems = workoutsToDisplay.length === pageSize;
		return { workouts, workoutsToDisplay, moreItems };
	}
	
	if (error) {
		return <p>{error.message}</p>
	}

	if (!isLoaded) {
		return <LoaderComponent text="Please wait while the Hasi is getting your page"/>
	} 

	return (
		<div>
			<SearchComponent searchHandler={search} clearHandler={clear} randomHandler={randomWorkout} />
			<button className="button add" onClick={setShowModal}>Add Workout</button>
			<div>
				<Modal title="New workout" show={showModal} handleClose={setShowModal} >
					<WorkoutForm key="workout-form" handleSave={createWorkout}/>
				</Modal>
			</div>
			<div>
				{currentWorkouts.map((workout) => (
					<div key={workout.name}>							
						<WorkoutRecordComponent
							key={workout.id}
							workout={workout}
							results={allResults.filter(x => x.workoutId === workout.id)}
							submitResult={submitResultState}
							deleteResult={deleteResultState}
						/>
					</div>
				))}
			</div>
			{
				moreWorkouts ? <button className="button add" onClick={more}>Load More Workouts</button> : <div></div>
			}
			<SnackbarComponent ref={wodSnackbarRef}/>
		</div>
	);
	
}

export default WorkoutContainerComponent;