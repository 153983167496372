import React from 'react';

export default class Modal extends React.Component {
	close = () => {
		this.props.handleClose()
  	}

    render() {
		const showHideClassName = this.props.show ? "modal display-on-top" : "modal display-none";
		return (  
			<div className={showHideClassName}>
				<div className="modal-main">
					<span>
						<h2>{this.props.title}</h2>
						<i onClick={this.close} className="fa fa-window-close"></i>
					</span>
                    {this.props.children}
				</div>
			</div>  
		)
  	}
}