export const Get = async (accessToken, url) => {
    const response = await fetch(url, { headers: { Authorization: `Bearer ${accessToken}` } });
    if (response.ok) {
        return await response.json();
    }

    throwError(response);
}

export const Post = async (accessToken, url, body) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json' },
        body: body
    };

    const response = await fetch(url, requestOptions);
    if (response.ok) {
        return await response.json();
    }

    throwError(response);
}

export const PostFile = async (accessToken, url, file) => {
    const formData = new FormData();
    formData.append('video', file.blob, file.name);
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization': `Bearer ${accessToken}`
        },
        body: formData
    };
    const response = await fetch(url, requestOptions);
    if (response.ok) {
        return await response.json();
    }

    throwError(response);
}

export const Delete = async (accessToken, url) => {
    const response = await fetch(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
        method: 'DELETE'
    });

    if (response.ok) {
        return;
    }

    throwError(response);
}

function throwError (response) {
    const responseError = {
        type: 'Error',
        message: response.message || 'Something went wrong',
        data: response.data || '',
        code: response.code || '',
    };

    let error = new Error();
    error = { ...error, ...responseError };
    throw (error);
}