import React from 'react';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export default class BarChartComponent extends React.Component {   
	render() {
		const options = {
			plugins: {
				title: {
				display: true,
				text: this.props.title,
				},
			},
			responsive: true,
			scales: {
				x: {
					stacked: true,
				},
				y: {
					stacked: true,
				},
			},
		};

		const colors = ["pink", "white", "purple", "grey", "black", "red", "yellow", "green", "yellow", "blue", "orange"];

		let labels = [];
		let datasets = [];
		let current = 0;

		for (const [key, val] of Object.entries(this.props.data)) {
			labels.push(key);

			val.forEach(wod => {
				let dataset = {
					label:  "",
					data: [],
					backgroundColor: ""
				};

				let provider = wod.provider;			
				if(!datasets.some(x => x.label === provider)) {
					dataset.label = provider;
					dataset.backgroundColor = colors.shift();
					datasets.push(dataset);
				} else {
					dataset = datasets.filter(x => x.label === provider)[0];
				}
				
				// Add padding zero if there is no workout for the the current provider that month. 
				while(dataset.data.length < current) {
					dataset.data.push(0);
				}

				dataset.data.push(wod.count);
			})
			
			current++;
		}

		const data = {
			labels,
			datasets: datasets
		};

		return (
			<Bar options={options} data={data} />
		);
    }
}