import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getAllActivityRecords } from '../../api/activityRecords';
import LoaderComponent from '../common/loader';

const ActivityContainerComponent = () => {
	const pageSize = 20;

	const { getAccessTokenSilently } = useAuth0();
	const [isLoaded, setIsLoaded] = useState();
	const [allRecords, setAllRecords] = useState([]);
	const [currentRecords, setCurrentRecords] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [moreRecords, setMoreRecords] = useState();
	const [error, setError] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoaded(false);
				const accessToken = await getAccessTokenSilently();
				
				let records = await getAllActivityRecords(accessToken);
				let itemsToDisplay = records.slice(0, pageSize);
				let moreItems = itemsToDisplay.length === pageSize;
	
				setAllRecords(records);
				setCurrentRecords(itemsToDisplay);
				setMoreRecords(moreItems);
				setIsLoaded(true);
			} catch (e) {
				setError(e?.message);
			}
		};
	  
		fetchData()
	}, [getAccessTokenSilently]);


	const more = async () => {
		let newpage = currentPage + 1;
		let start = currentPage * pageSize;
		let end = start + pageSize;
		let nextItems = allRecords.slice(start, end);
		let moreItems = nextItems.length === pageSize;

		const updatedRecords = currentRecords.concat(nextItems);

		setCurrentRecords(updatedRecords);
		setMoreRecords(moreItems);
		setCurrentPage(newpage);
	}

	if (error) {
		return <p>{error}</p>
	}

	if (!isLoaded) {
		return <LoaderComponent text="Please wait while the Hasi is getting your page"/>
	}
	return (
		<>
			{
				currentRecords.map((activityRecord) => (
					<div key={activityRecord.userName + activityRecord.timeStamp}>							
						<p>{activityRecord.timeStamp.split("T")[0]}: {activityRecord.userName} completed {activityRecord.workoutName}</p>
					</div>
				))
			}
			{
				moreRecords ? <button className="button add" onClick={() => more}>Load More Records</button> : <span></span>
			}
		</>
	);
}

export default ActivityContainerComponent;