import React, { useState } from 'react';

import StarRatingComponent from 'react-star-rating-component';
import Modal from '../common/modal';
import WorkoutResultForm from './workoutResultForm';
import { useAuth0 } from '@auth0/auth0-react';

const WorkoutRecordComponent = (props) => {
    const { user } = useAuth0();
    const [showResults, setShowResults] = useState(false);
	const [showModal, setShowModal] = useState(false);

    const displayModal = () =>  {
        setShowModal(true);
    };

    const hideModal = () =>  {
        setShowModal(false);
    };

    const handleToggle = () =>  {
        showResults ? setShowResults(false) : setShowResults(true);
    }
    
    const submitResult = (formState) => {
		props.submitResult(formState);
        hideModal();
	}

	const deleteResult = (id) => {
        props.deleteResult(id);
	}

    const workoutHeaderStyle = props.results.filter(x => x.userInfo.email === user.email).length > 0 ? "fa fa-id-badge fa-lg has-results pointer" : "fa fa-id-badge fa-lg pointer";
    let resultIconStyle = "fa fa-plus fa-lg";
    let results;
    if(showResults) {
        results = <ResultDetailComponent results={props.results} handleDelete={deleteResult} />
        resultIconStyle = "fa fa-minus fa-lg";
    }
    return (
        <div key={props.workout.name} className="workout-card">
            <div className="workout-card-heading">
                <div>
                    <h4 className="workout-heading-4">
                        {props.workout.name}
                        <br/>
                        <p>{props.workout.date.split('T')[0]}</p>
                    </h4>
                    
                </div>
                <i className={workoutHeaderStyle} onClick={displayModal}></i>
            </div>
            <hr/>
            
            <WorkoutDetailComponent workoutDescription={props.workout.description} />
            {
                props.results.length > 0 ? (            
                    <div className="result-container">
                        <div onClick={handleToggle} className="result-container-heading pointer">
                            <h4 className="workout-heading-4">RESULTS</h4>
                            <i className={resultIconStyle}></i>
                        </div>
                        {results}
                    </div>
                ):
                (
                    <span></span>
                ) 
            }
            <Modal title={props.workout.name} show={showModal} handleClose={hideModal} >
                <WorkoutResultForm workoutId={props.workout.id} handleSave={submitResult}/>
            </Modal>
        </div>
    )
}

const WorkoutDetailComponent = (props) => {
    return (
        <div className="workout-details">
            <p>{props.workoutDescription.trim()}</p>
        </div>     
    )
}

const ResultDetailComponent = (props) => {
    return (  
        <div>
            {props.results.map((item) => 
            (
                <ResultComponent key={item.id} id={item.id} athlete={item.userInfo.name} rating={item.rating} score={item.score} date={item.date} handleDelete={props.handleDelete}/>
            ))}
        </div>  
    );
}

const ResultComponent = (props) => {
    const { user } = useAuth0();
    const { 'https://hasiwod.carldanell.se/user_role': role } = user;
    const deleteResult = () => {
        props.handleDelete(props.id);
    }

    let date = props.date.split("T")[0];
    let removeIcon = role === 'admin' ? <i className="fa fa-trash fa-lg white pointer" onClick={deleteResult}></i> : <span></span>
    return (
        <div className="result-container-body">
            <div className="workout-record-result-header-container">
                <p className="workout-results-text">{props.athlete} - {date}</p>
                {removeIcon}
            </div>
            <StarRatingComponent 
                    name="Rating"
                    starCount={5} 
                    value={props.rating} 
                    starColor={'#FFC0CB'} 
                    className={"rating"} 
                />
            <p className="workout-results-text">{props.score}</p>
            <hr className="app-header-border"/>
        </div>
    );
}

export default WorkoutRecordComponent;