import React from 'react';

export default class SnackBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			message: ""
		};
	}

	show = (message) => {
		if(this.state.active) {
			return;
		}

		this.setState({active: true, message: message});
		setTimeout(() => {
			this.setState({active: false});
		}, 2700);
	}

    render() {
		const className = this.state.active ? "snackbar show" : "snackbar";
		return (  
			<div className={className}>
				<p>{this.state.message}</p>
			</div>  
		)
	}
}