import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, Route, Routes} from 'react-router-dom';

import Logo from './logo.png';

import { WorkoutsPage } from './pages/workoutsPage';
import { LiftsPage } from './pages/liftsPage';
import { ActivityPage } from './pages/activityPage';
import { ProfilePage } from './pages/profilePage';
import NavBar from './components/common/navbar';

const App = () => {
	const { isAuthenticated, loginWithRedirect } = useAuth0();
	const handleLogin = async () => {
		if (isAuthenticated) {
			<Navigate to={`/workouts`}/>
		} else {
			await loginWithRedirect({
				appState: {
					returnTo: "/workouts",
				  },
			});
		}
	};

	return (
		<div className="app">
			<div className="app-container"> 

				{
					!isAuthenticated ? 
					(
						<div className="start-container">
							<img src={Logo} className="app-header-img-lg"  alt="HasiWod"/>
							<button className="button login" onClick={handleLogin}>Login</button>
						</div>
					) :
					(
						<>
							<NavBar />
							<Routes>
								<Route path="/" element={<WorkoutsPage />} />
								<Route path="/workouts" element={<WorkoutsPage />} />
								<Route path="/activity" element={<ActivityPage />} />
								<Route path="/lifts" element={<LiftsPage />} />
								<Route path="/profile" element={<ProfilePage />} />
							</Routes>
						</>
					)
				}
			</div>
		</div>
	);
}

export default App;