import { Get, Post, PostFile, Delete } from './common'

export const getLiftResults = async (accessToken, id) => {
    const url = process.env.REACT_APP_BASE_API_ADDRESS + "liftResults?liftId=" + id;
    return await Get(accessToken, url);
}

export const getAllLiftResults = async (accessToken) => {
    const url = process.env.REACT_APP_BASE_API_ADDRESS + "liftResults/all";
    return await Get(accessToken, url);
}

export const submitResult = async (accessToken, form) => {
    const url = process.env.REACT_APP_BASE_API_ADDRESS + "liftResults/";
    const body = JSON.stringify(form);
    return await Post(accessToken, url, body);
}

export const submitVideo = async (accessToken, id, file) => {
    const url = process.env.REACT_APP_BASE_API_ADDRESS + "liftResults/" + id + "/video";
    return await PostFile(accessToken, url, file);
}

export const getVideoUri = async (accessToken, liftId, videoName) => {
    const url = process.env.REACT_APP_BASE_API_ADDRESS + "liftResults/" + liftId + "/video/" + videoName + "/sas";
    const res = await Get(accessToken, url);
    return res;
}

export const deleteResult = async (accessToken, id) => {
    const url = process.env.REACT_APP_BASE_API_ADDRESS + "liftResults?liftResultId=" + id;
    return await Delete(accessToken, url);
}